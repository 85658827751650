import React, { useState, useEffect } from "react";

const QrUser = React.createContext([{}, () => {}]);

const initialState = {token: '', licence: '', email: ''};

const UserProvider = (props) => {
  const [state, setState] = useState(initialState);
  const name = props.name;

  useEffect(() => {
    sessionStorage.setItem('access-token', state.token);
    sessionStorage.setItem('licence', state.licence);
    sessionStorage.setItem('email', state.email)
  }, [state]);

  return (
    <QrUser.Provider value={[state, setState, name]}>
      {props.children}
    </QrUser.Provider>
  );
};

export { QrUser, UserProvider };

export const serverURL = 'http://qrxmenu.com:4000/';
//export const socketServerMobile = 'http://85.105.29.110:4001/';
//export const socketServer = 'https://qrxmenu.com:4001/';
const fileURL = 'http://qrxmenu.com/qr/';
export const imageURL = 'http://qrxmenu.com/qr/server/files/';

export const LIST_API = serverURL + 'list/';
export const ICON_API = serverURL + 'icons/';
export const LOG_API = serverURL + 'log/';
export const IMAGE_API = serverURL + 'images/';
export const QR_API = serverURL + 'qrmain/';
export const UPLOAD_FILE = serverURL + 'upload/';
export const PREPARE_FOLDER = serverURL + 'start/';
export const FINISH_UPLOAD = serverURL + 'finish/';
export const CLEAN_UPLOAD = serverURL + 'finishclean/';

export const ICONS_URL = fileURL + 'icons/';
export const TEMP_URL = fileURL + 'temp/';

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import CreateAdmin from './components/user/CreateAdmin'; 
import { UserProvider } from "./components/context/QrUser";
import { QrProvider } from './components/context/QrData';
import Loader from './components/utils/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';

const QrMenu = lazy(() => import('./components/screens/QrMenu'));
const QrLog = lazy(() => import('./components/screens/QrLog'));
const Entry = lazy(() => import('./components/route/Entry'));
const ResetPassword = lazy(() => import('./components/user/ResetPassword'));

function App() {
  //const params = window.location.pathname.split('/');
  const searchQuery = new URLSearchParams(window.location.search);
  //const name = params[params.length - 1];
  const name = searchQuery.get('n');
  const detail = searchQuery.get('d');
  const token = searchQuery.get('token');
  //const refresh = searchQuery.get('refresh');
  //if (refresh === 'none') return;

  return (
    <Router>
      <Suspense fallback={<Loader/>}>
        <Routes> 
          {/* <Route exact path="/manage19671211_0200" 
            element={
            <CreateAdmin/>}>
            </Route> */}
          <Route exact path="/reset-password" 
            element={
            <ResetPassword token={token}/>}>
          </Route>
          <Route exact path="/edit" 
            element={
            <UserProvider name={name}>
              <QrProvider name={name}>
                <Entry actions="edit"/>
              </QrProvider>
            </UserProvider>}>
          </Route>
          <Route exact path="/des" 
            element={
            <UserProvider>
                <Entry actions="des"/>
            </UserProvider>}>
          </Route>
          <Route exact path="/users" 
            element={
            <UserProvider>
                <Entry actions="users"/>
            </UserProvider>}>
          </Route>
          <Route exact path="/admin" 
            element={
            <UserProvider>
              <Entry actions="admin"/>
            </UserProvider>}>
          </Route>
          <Route path="/"
            element={
            <QrProvider name={name}>    
                <QrLog name={name}/>
                <QrMenu name={name} detail={detail} show="final"/>          
            </QrProvider> }>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App;



import React, { Component } from 'react';
import axios from 'axios';
import { LIST_API } from '../utils/constants';
import { ICONS_URL } from '../utils/constants';
import { QR_API } from '../utils/constants';
import { TEMP_URL } from '../utils/constants';
import { UPLOAD_FILE } from '../utils/constants';
import { PREPARE_FOLDER } from '../utils/constants';
import { FINISH_UPLOAD } from '../utils/constants';
import { CLEAN_UPLOAD } from '../utils/constants';

const QrContext = React.createContext();
let axiosInstance;

const finish = async(state) => {
    axiosInstance = axios.create({
        headers: {
          token : sessionStorage.getItem('access-token')
        }
    });
    await axiosInstance.get(CLEAN_UPLOAD, {params: {name: state.name, website: state.website, type: state.type, title: state.title, active: state.active, control: state.control}});
    return true;
}

/*async function finishServer(state, icons, files, uploadfiles, json) {
    let response = await axiosInstance.get(PREPARE_FOLDER, {params: {name: state.name, icons: JSON.stringify(icons), files: JSON.stringify(files)}});
    if (response.status === 200) {
        uploadfiles.forEach(async(file) => {
            response = await axiosInstance.post(UPLOAD_FILE, {params: {file: file, name: state.name}});
            if (response.status !== 200) {
                return false;
            } 
        })
        response = await axiosInstance.post(FINISH_UPLOAD, {params: {name: state.name, website: state.website, title: state.title, whatsapp: state.whatsapp, phone: state.phone, type: state.type, json: json}});
        if (response.status !== 200) {
            return false;
        } 
    } else {
        return false;
    }
    return true;
}*/

async function finishServer(state, icons, files, uploadfiles, json) {
    let response = await axiosInstance.get(PREPARE_FOLDER, {params: {name: state.name, icons: JSON.stringify(icons), files: JSON.stringify(files)}});
    if (response.status === 200) {
        let attachmentI = {i: -1};
        UploadMe();
        
        function UploadMe() {
            attachmentI.i++;
            if(attachmentI.i < uploadfiles.length){
                const file = uploadfiles[attachmentI.i];
                state.dispatch({type: "SET_PERCENT", payload: parseInt(attachmentI.i / uploadfiles.length * 100) + ' %'});
                axiosInstance.post(UPLOAD_FILE, {params: {file: file, name: state.name}}).then((response) => {
                    if (response.status !== 200) {
                        return false;
                    } else {
                        UploadMe();
                    }
                });
                
            } else if(attachmentI.i === uploadfiles.length) {
                axiosInstance.post(FINISH_UPLOAD, {params: {name: state.name, website: state.website, title: state.title, active: state.active, type: state.type, control: state.control, json: json}}).then((response) => {
                        if (response.status !== 200) {
                            state.dispatch({type: "UPLOAD_FINISHED", payload: {previewExists: ''}});
                        } else {
                            state.dispatch({type: "UPLOAD_FINISHED", payload: {previewExists: '__' + state.name}});
                        }
                        state.dispatch({type: "SET_PERCENT", payload: 0})
                    })
                
            } else {
                return true;
            }
        }
    }
} 


const uploadFiles = (state) => {
    axiosInstance = axios.create({
        headers: {
          token : sessionStorage.getItem('access-token')
        }
    });
    let icons = [];
    let files = [];
    let uploadfiles = [];
    let json = '{';
    state.langs.forEach(lang => {
        const langData = state.langsAvailable[lang.index];
        if(lang.tabs.length > 0) {
            icons.push({file: langData.icon, filename: langData.icon});
            let jsontabs = [];
            let tabindex = 1;
            lang.tabs.forEach(tab => {
                icons.push({file: tab.icon, filename: tab.icon});
                let jsonimages = [];
                let fileindex = 1;
                tab.images.forEach(image => {
                    const filename = tabindex.toString() + langData.lang + '-' + fileindex + '.jpg';
                    if (image.source === 'server') {
                        files.push({file: image.icon, filename: filename}); 
                    } else if (image.source === 'local') { 
                        uploadfiles.push({base64: image.icon, filename: filename});
                    } 
                    if (state.type === 0) {
                        jsonimages.push({id: fileindex, name: filename});
                    } else if (state.type !== 0 && image.source === 'none') {
                        jsonimages.push({id: fileindex, name: '-', text: image.text, description: image.description, price: image.price, options: image.options});
                    } else {
                        jsonimages.push({id: fileindex, name: filename, text: image.text, description: image.description, price: image.price, options: image.options});
                    }
                    fileindex++;
                })
                jsontabs.push({tabid: tabindex - 1, tab: tab.name, icon: tab.icon.substring(tab.icon.lastIndexOf('/') + 1), start: tab.start,
                    end: tab.end, images: jsonimages});
                tabindex++;
            })
            const jsonlang = [{[langData.lang]: {id: langData.index, lang: langData.name, icon: langData.icon.substring(langData.icon.lastIndexOf('/') + 1), tabs: jsontabs}}];
            json += JSON.stringify(jsonlang).slice(2, -2) + ',';
        }
    })
    json = json.slice(0, -1) + '}';
    finishServer(state, icons, files, uploadfiles, json);
    
}

const reducer = (state, action) => {
    let langs = [...state.langs];
    const lang = state.currentLang;
    const orderTabs = (unsortedtabs) => {
        let i = 0;
        let j = state.currentTab;
        unsortedtabs.forEach(tab => {
            if (state.currentTab === tab.index) j = i;
            tab.index = i;
            i++;
        });
        return({tabs: unsortedtabs, currentTab: j});
    }
    switch (action.type) {
        case "NO_PREVIEW" :
            return {
                ...state,
                previewExists: ''
            }
        case "FINISH" :
            finish(state);
            return {
                ...state,
                previewExists: ''
            }
        case "UPLOAD" :
            uploadFiles(state);
            return {
                ...state,
                isSubmitting: true,
            }
        case "UPLOAD_FINISHED" :
            return {
                ...state,
                isSubmitting: false,
                previewExists: action.payload.previewExists
            }
        case "CHANGE_LANG" :
            const newLang = action.payload;
            return {
                ...state,
                currentLang: newLang,
                currentTab: -1
            }
        case "CHANGE_TAB" :
            return {
                ...state,
                currentTab: action.payload
            }
        case "CHANGE_TABTEXT" :
            if (state.currentTab !== action.payload.index) return;
            const currentTabs = langs[lang].tabs;
            currentTabs[state.currentTab].name = action.payload.text;
            langs[lang].tabs = currentTabs;
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "CHANGE_LABEL" :
            const index = langs[lang].tabs[state.currentTab].images.indexOf(action.payload.image);
            const image = langs[lang].tabs[state.currentTab].images[index];
            image.price = action.payload.price;
            image.text = action.payload.text;
            image.description = action.payload.description;
            langs[lang].tabs[state.currentTab].images[index] = image;
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "ADD_TAB" :
            langs[lang].tabs = orderTabs(action.payload).tabs;
             return {
                ...state,
                langs: langs,
                currentTab: action.payload.length - 1,
                previewExists: ''
            }
        case "DELETE_TAB" :
            const filtertabs = (tab) => { return tab.index !== parseInt(action.payload)}
            langs[lang].tabs = orderTabs(langs[lang].tabs.filter(filtertabs)).tabs;
            let currentTab;
            langs[lang].tabs.length > 0 ? currentTab = 0 : currentTab = -1;
            return {
                ...state,
                currentTab: currentTab,
                langs: langs,
                previewExists: ''
            }
        case "SCHEDULE_TAB" :
            Object.assign(langs[lang].tabs[state.currentTab], {start: action.payload.start, end: action.payload.end}); 
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "DELETE_IMAGE" :
            const filterimages = (image) => { return image.index !== parseInt(action.payload)}
            langs[lang].tabs[state.currentTab].images = langs[lang].tabs[state.currentTab].images.filter(filterimages);
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "CHANGE_FILES" :
            langs[lang].tabs[state.currentTab].images = action.payload;
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "ORDER_TABS" :
            let orderedtabs = orderTabs(action.payload);
            langs[lang].tabs = orderedtabs.tabs;
            return {
                ...state,
                langs: langs,
                currentTab: orderedtabs.currentTab,
                previewExists: ''
            }
        case "ADD_OPTION" :
            langs[lang].tabs[state.currentTab].images[action.payload.index].options.push(action.payload.options);
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "DELETE_OPTION" :
            langs[lang].tabs[state.currentTab].images[action.payload.index].options.splice(action.payload.optionsIndex, 1);
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "CHANGE_OPTION" :
            langs[lang].tabs[state.currentTab].images[action.payload.index].options = action.payload.options;
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "ADD_DETAIL" :
            langs[lang].tabs[state.currentTab].images[action.payload.index].options[action.payload.optindex].details.push(action.payload.options);
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "CHANGE_DETAIL" :
            langs[lang].tabs[state.currentTab].images[action.payload.index].options[action.payload.optindex].details = action.payload.details;
            return {
                ...state,
                langs: langs,
                previewExists: ''
            }
        case "SET_ORDERS" :
            return {
                ...state,
                orders: action.payload
            }
        case "SET_PERCENT" :
            return {
                ...state,
                uploadPercent: action.payload
            }
        default:
            return state
    }
}

class QrProvider extends Component {
    
    state = {
        exists: true,
        previewExists: '',
        isSubmitting: false,
        website: '',
        active: true,
        control: '',
        type: 0,
        icons: [],
        tabsAvailable: [],
        langsAvailable: [],
        currentLang: 0,
        currentTab: -1,
        langs: [],
        orders: [],
        name: this.props.name,
        uploadPercent: 0,
        dispatch: action => {
            this.setState(state => reducer(state, action))
        }
    }
    
    decode = (qr, langs, name, type) => {
        const tempUrl = TEMP_URL + name + '/';
        const tempIconUrl = tempUrl + 'images/';
        const data = JSON.parse(qr.data ? qr.data : '{}');
        let langsTemp = langs;
        for(let lang of Object.keys(data)) {
            const langData = data[lang];
            // lang change values
            langData.tabs.forEach((tab) => {
                const tabData = tab.images;
                // tab change values
                langsTemp[langData.id].tabs.push({index: tab.tabid, name: tab.tab, icon: tempIconUrl + tab.icon, start: tab.start, end: tab.end, images: []});
                tabData.forEach((image) => {
                    // image change values
                    type === '0' ?
                    langsTemp[langData.id].tabs[tab.tabid].images.push({
                        index: image.id, source: 'server', name: tempUrl + image.name, icon: tempUrl + image.name}) :
                        image.name === '-' ?
                            langsTemp[langData.id].tabs[tab.tabid].images.push({
                                index: image.id, source: 'none', name: '', icon: '', text: image.text, description: image.description, price: image.price, options: image.options}) :
                            langsTemp[langData.id].tabs[tab.tabid].images.push({
                                index: image.id, source: 'server', name: tempUrl + image.name, icon: tempUrl + image.name, text: image.text, description: image.description, price: image.price, options: image.options});
                })
            })
        }
        // set state
        this.setState({
            langs: langsTemp
        });
    }

    componentDidMount = async () => {
        let name = this.state.name;
        let draft = false;
        const dataURL = LIST_API + name;
        const draftURL = LIST_API + '__' + name;
         // QR menu
         // check if draft available
        let dataResponse = await axios.get(draftURL);
        if (dataResponse.data === '') {
            // no draft
            dataResponse = await axios.get(dataURL);
        } else {
            // draft exists
            this.setState({
                previewExists: '__' + name
            })
            draft = true;
        }
        if (dataResponse.data === '') {
            // file & items not in dbase
            this.setState({
                exists: false
            });
            return;
        }
        if (!dataResponse.data.active) {
            // inactive entry
            this.setState({
                exists: false
            });
            alert('Inactive');
            return;
        }
        document.title = dataResponse.data.title;
        // Copy files, get languages and tab titles
        const mainResponse = await axios.get(QR_API, { params: { url: name, draft: draft }});
        const iconsURL = ICONS_URL;
        const lng = JSON.parse(mainResponse.data[0].lang);
        const tab = JSON.parse(mainResponse.data[0].tab);
        const langsAvailable = lng.lang.map(item => Object.assign({index: item.index}, {lang: item.lang}, {name: item.name}, {icon: iconsURL + item.icon}));
        const tabsAvailable = tab.tab.map(item => Object.assign({index: item.index}, {name: item.tab}, {icon: iconsURL + item.icon}));
        const langs = lng.lang.map(item => Object.assign({index: item.index}, {tabs: []}));
        this.setState({
            langsAvailable: langsAvailable,
            tabsAvailable:tabsAvailable,
            langs: langs,
            website: dataResponse.data.website,
            type: dataResponse.data.type,
            title: dataResponse.data.title,
            control: dataResponse.data.control,
            active: dataResponse.data.active,
        });
        this.decode(dataResponse.data, langs, name, dataResponse.data.tpe);  
    }

    render() {
        if (this.state.exists) {
        return (
            <QrContext.Provider value = {this.state}>
                {this.props.children}
            </QrContext.Provider>
        ) } else {return null}
    }
}

const QrConsumer = QrContext.Consumer;

export { QrProvider, QrConsumer, QrContext }
